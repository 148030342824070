/**
 * Copyright (C) Sitevision AB 2002-2021, all rights reserved
 *
 * @author eric
 */

import sv from '@sv/core';

const toasts = (function () {
  const defaults = {
    notifier: {
      delay: 4,
      showCheckmark: false,
      classes: {
        base: 'sv-toast__container',
        prefix: 'env-toast--',
        toast: 'env-toast',
        bottomLeft: 'sv-bottom-left',
        visible: 'env-toast--visible',
        hidden: 'env-toast--hide',
        close: 'env-toast__close',
      },
    },
  };

  const closeButtonTemplate = `<svg data-dismiss="close" class="env-icon env-icon--medium">
         <use data-dismiss="close" xlink:href="/sitevision/envision-icons.svg#icon-delete"></use>
      </svg>`;

  const iconCheckDoneTemplate = `<div class="sv-toast-checkmark env-m-left--medium"><svg class="env-icon env-icon--medium">
         <use xlink:href="/sitevision/envision-icons.svg#icon-check-done"></use>
      </svg></div>`;

  var element,
    openInstances = [],
    classes = defaults.notifier.classes,
    baseClass = classes.base;

  function clearContents(element) {
    while (element.lastChild) {
      element.removeChild(element.lastChild);
    }
  }

  function addClass(element, classNames) {
    element.className += ' ' + classNames;
  }

  const transition = (function () {
    var t, type;
    var supported = false;
    var transitions = {
      animation: 'animationend',
      OAnimation: 'oAnimationEnd oanimationend',
      msAnimation: 'MSAnimationEnd',
      MozAnimation: 'animationend',
      WebkitAnimation: 'webkitAnimationEnd',
    };

    for (t in transitions) {
      if (document.documentElement.style[t] !== undefined) {
        type = transitions[t];
        supported = true;
        break;
      }
    }

    return {
      type: type,
      supported: supported,
    };
  })();

  const on = function (el, event, fn) {
    el.addEventListener(event, fn);
  };

  const off = function (el, event, fn) {
    el.removeEventListener(event, fn);
  };

  function initialize(instance) {
    if (!instance.__internal) {
      instance.__internal = {
        delay: defaults.notifier.delay,
      };

      element = document.createElement('DIV');
      updatePosition();
    }

    //add to DOM tree.
    if (element.parentNode !== document.body) {
      document.body.appendChild(element);
    }
  }

  function pushInstance(instance) {
    instance.__internal.pushed = true;
    openInstances.push(instance);
  }

  function popInstance(instance) {
    openInstances.splice(openInstances.indexOf(instance), 1);
    instance.__internal.pushed = false;
  }

  function updatePosition() {
    element.className = baseClass;
    addClass(element, classes.bottomLeft);
  }

  function create(div, callback) {
    function clickDelegate(event, instance) {
      if (event.target.getAttribute('data-dismiss') === 'close') {
        instance.dismiss(true);
      }
    }

    function transitionDone(event, instance) {
      // unbind event
      off(instance.element, transition.type, transitionDone);
      // remove the message
      element.removeChild(instance.element);
    }

    function delegate(context, method) {
      return function () {
        if (arguments.length > 0) {
          const args = [];

          for (var x = 0; x < arguments.length; x += 1) {
            args.push(arguments[x]);
          }
          args.push(context);

          return method.apply(context, args);
        }

        return method.apply(context, [null, context]);
      };
    }

    function initialize(instance) {
      if (!instance.__internal) {
        instance.__internal = {
          pushed: false,
          delay: undefined,
          timer: undefined,
          clickHandler: undefined,
          transitionEndHandler: undefined,
          transitionTimeout: undefined,
          showCheckmark: undefined,
        };
        instance.__internal.clickHandler = delegate(instance, clickDelegate);
        instance.__internal.transitionEndHandler = delegate(
          instance,
          transitionDone
        );
      }

      return instance;
    }

    function clearTimers(instance) {
      clearTimeout(instance.__internal.timer);
      clearTimeout(instance.__internal.transitionTimeout);
    }

    function reflow(element) {
      if (element === undefined) {
        element = document.documentElement;
      }
      void element.offsetHeight;
    }

    return initialize({
      element: div,

      push: function (_content, _ttl, useCheckmark) {
        if (!this.__internal.pushed) {
          pushInstance(this);
          clearTimers(this);

          var content, wait;

          switch (arguments.length) {
            case 0:
              wait = this.__internal.delay;
              break;
            case 1:
              if (typeof _content === 'number') {
                wait = _content;
              } else {
                content = _content;
                wait = this.__internal.delay;
              }
              break;
            case 2:
              content = _content;
              wait = _ttl;
              break;
            case 3:
              content = _content;
              wait = _ttl;
              this.__internal.showCheckmark = useCheckmark;
          }

          // set contents
          if (typeof content !== 'undefined') {
            this.setContent(content);
          }

          element.appendChild(this.element);

          reflow(this.element);
          addClass(this.element, classes.visible);
          // attach click event
          on(this.element, 'click', this.__internal.clickHandler);

          return this.delay(wait);
        }

        return this;
      },

      ondismiss: function () {},

      callback: callback,

      dismiss: function (clicked) {
        if (this.__internal.pushed) {
          clearTimers(this);
          if (
            !(
              typeof this.ondismiss === 'function' &&
              this.ondismiss.call(this) === false
            )
          ) {
            //detach click event
            off(this.element, 'click', this.__internal.clickHandler);
            // ensure element exists
            if (
              typeof this.element !== 'undefined' &&
              this.element.parentNode === element
            ) {
              //transition end or fallback
              this.__internal.transitionTimeout = setTimeout(
                this.__internal.transitionEndHandler,
                transition.supported ? 1000 : 100
              );

              addClass(this.element, classes.hidden);

              // custom callback on dismiss
              if (typeof this.callback === 'function') {
                this.callback.call(this, clicked);
              }
            }

            popInstance(this);
          }
        }

        return this;
      },

      delay: function (wait) {
        clearTimers(this);
        this.__internal.delay =
          typeof wait !== 'undefined' && !isNaN(+wait)
            ? +wait
            : toasts.__internal.delay;
        if (this.__internal.delay > 0) {
          var self = this;

          this.__internal.timer = setTimeout(function () {
            self.dismiss();
          }, this.__internal.delay * 1000);
        }

        return this;
      },

      setContent: function (content) {
        const div = document.createElement('div');

        addClass(div, 'env-toast__content');

        if (typeof content === 'string') {
          clearContents(this.element);
          div.innerHTML = content;
        } else if (
          content instanceof window.HTMLElement &&
          div.firstChild !== content
        ) {
          clearContents(this.element);
          div.appendChild(content);
        }

        this.element.appendChild(div);
        this.element.setAttribute('role', 'status');
        this.element.setAttribute('aria-live', 'polite');

        if (this.__internal.showCheckmark) {
          this.element.insertAdjacentHTML('afterbegin', iconCheckDoneTemplate);
        }

        const closeBtn = document.createElement('button');

        addClass(closeBtn, classes.close);
        closeBtn.insertAdjacentHTML('beforeend', closeButtonTemplate);
        closeBtn.setAttribute('aria-label', sv.i18n.getText('common', 'close'));
        closeBtn.setAttribute('data-dismiss', 'close');

        this.element.appendChild(closeBtn);

        return this;
      },
    });
  }

  return {
    create: function (type, callback) {
      //ensure notifier init
      initialize(this);
      //create new toast message
      const div = document.createElement('div');

      div.className =
        classes.toast +
        (typeof type === 'string' && type !== ''
          ? ' ' + classes.prefix + type
          : '');

      return create(div, callback);
    },
  };
})();

function transformMessage(heading, message) {
  return '<strong>' + heading + '</strong>' + ' ' + message;
}

document.addEventListener('sv-publish-toast', (event) => {
  const toast = event.detail;

  if (toast.heading) {
    toast.message = transformMessage(toast.heading, toast.message);
  }

  toasts
    .create(toast.type, toast.callback)
    .push(toast.message, toast.ttl, toast.checkmark);
});
